<template>
  <div class="facial-features q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['rednessDiscomfortFrequency'])">
      <single-answer-question
        title="label.rednessDiscomfortFrequency"
        :value="rednessDiscomfortFrequency"
        :options="$options.discomfortFrequencyOptions"
        dysfunction="skin-redness"
        @input="onFieldChange('rednessDiscomfortFrequency', $event)"
      />
    </question-with-error-wrap>

    <fade-in-transition-wrap>
      <question-with-error-wrap
        v-if="isDurationVisible"
        class="q-mt24 q-mt32-md"
        :error="getFieldsError(['rednessDuration'])"
      >
        <single-answer-question
          id="redness-duration"
          title="label.permanentRednessDuration"
          :value="rednessDuration"
          :options="$options.durationOptions"
          dysfunction="skin-redness"
          @input="onFieldChange('rednessDuration', $event)"
        />
      </question-with-error-wrap>
    </fade-in-transition-wrap>
  </div>
</template>

<script>
import FadeInTransitionWrap from '@/components/wrappers/FadeInTransitionWrap';
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';
import {
  REDNESS_DISCOMFORT_FREQUENCY,
  REDNESS_DURATION
} from '@/modules/questionnaire/api/constants';

const DISCOMFORT_FREQUENCY_OPTIONS = [
  {
    value: REDNESS_DISCOMFORT_FREQUENCY.UNDER_A_WEEK,
    text: 'rednessDiscomfortFrequency.underAWeek'
  },
  {
    value: REDNESS_DISCOMFORT_FREQUENCY.TWO_TO_THREE_WEEKS,
    text: 'rednessDiscomfortFrequency.twoToThreeWeeks'
  },
  {
    value: REDNESS_DISCOMFORT_FREQUENCY.ALL_THE_TIME,
    text: 'rednessDiscomfortFrequency.allTheTime'
  }
];

const DURATION_OPTIONS = [
  { value: REDNESS_DURATION.UNDER_A_YEAR, text: 'permanentRednessDuration.underAYear' },
  { value: REDNESS_DURATION.ONE_TO_THREE_YEARS, text: 'permanentRednessDuration.oneToThreeYears' },
  { value: REDNESS_DURATION.OVER_THREE_YEARS, text: 'permanentRednessDuration.overThreeYears' }
];

export default {
  name: 'RednessDetailTemplate',
  components: { FadeInTransitionWrap, QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  discomfortFrequencyOptions: DISCOMFORT_FREQUENCY_OPTIONS,
  durationOptions: DURATION_OPTIONS,
  props: {
    rednessDiscomfortFrequency: {
      type: String,
      default: ''
    },
    rednessDuration: {
      type: String,
      default: ''
    },
    isDurationVisible: {
      type: Boolean,
      default: false
    }
  }
};
</script>
